<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        2024年山东省医学影像质控中心工作会议简报
      </div>
      <section>
        <div class="div_p">
          3月8日至9日，由山东省医学影像学质量控制中心主办，山东省立医院菏泽医院（菏泽市立医院）、菏泽市医学影像质量控制中心承办的山东省医学影像质控中心工作会议暨医学影像质量控制培训班在菏泽召开。
        </div>
        <div>
          <img src="@/assets/sdsyx/1.png" alt="">
        </div>
        <div class="div_tit">
          质控工作会议
        </div>
        <div class="div_p">
          在3月9日的开幕式上，菏泽市卫生健康委员会一级调研员主任张佩宪、山东省医学影像学质量控制中心主任山东第一医科大学附属省立医院副院长王光彬教授、国家放射影像质控中心副主任北京协和医院薛华丹教授、山东省医学影像质控中心全体委员及山东省地市级质控中心全体负责人及省内外多位影像专家参加了会议。
        </div>
        <div class="div_p">
          菏泽市卫生健康委员会一级调研员主任张佩宪致辞时表示：山东省菏泽市卫健委一直高度重视影像质控工作，注重完善质控组织架构建设，希望菏泽市医学影像质量控制工作在省级和国家级质控中心的指导下能越做越好。
        </div>
        <div>
          <img src="@/assets/sdsyx/2.png" alt="">
        </div>
        <div class="div_p">
          国家放射影像质控中心主任金征宇教授通过线上视频表达了对山东省医学影像事业的肯定和期许，也指出了2024年全国影像质控工作的重点应围绕在危急值识别、危急值上报、对比剂外渗等不良事件质量控制等关键问题开展。
        </div>
        <div>
          <img src="@/assets/sdsyx/3.png" alt="">
        </div>
        <div class="div_p">
          王光彬教授致辞并宣读了刚刚批复的山东省内的国家质控中心全国哨点医院名单，并寄语各家哨点医院在全省带好头，将全国影像质控工作坚决落实到位。
        </div>
        <div>
          <img src="@/assets/sdsyx/4.png" alt="">
        </div>
        <div class="div_tit">
          王光彬致辞
        </div>
        <div class="div_p">
          国家放射影像质控中心副主任薛华丹教授为山东省质控同道宣讲了2023年国家质控中心的工作情况，并重点介绍了2024年国家医学影像质控指标的工作内容，倡导在山东省内进行更为紧密有效的质控工作“织网”，鼓励大家在单位间、行业间“破壁”增强交流，传达了国家卫健委
          “向死亡学习”的号召。
        </div>
        <div class="div_p">
          与会的省内外专家还分别围绕《DRG/DIP运营模式下影像科的转型与发展》、《山东省医学影像互认方案介绍》、《浙江省影像互认规范介绍》、《心血管CT扫描规范和质量控制》、《冠状动脉CTA的质控要点》等质控议题展开了精彩演讲和讨论。
        </div>
        <div class="div_p">
          8日，山东省医学影像质控专家委员会2024年第一次工作会议顺利召开。山东省医学影像学质量控制中心主任，山东第一医科大学附属省立医院副院长王光彬教授主持会议。山东省医学影像质控中心常务副主任委员张翼教授汇报2023年工作总结及2024年工作计划，与会专家委员围绕2024年重点工作发表意见，围绕国家卫健委的检查互认推荐、16地市影像质控工作落实情况及当前影像质控存在共性问题和解决方法展开了热烈的讨论。同时，省内部分市区质控工作负责人结合自己本地医学影像质控工作开展情况，做了总结、汇报，并交流经验。
        </div>
        <div class="div_p">
          风好正是扬帆时，奋楫逐浪向未来。山东省医学影像质控中心工作会议暨医学影像质量控制培训班的成功举办，不仅为医学影像领域的专家学者提供了一个交流与学习的平台，也大大提高了山东省医学影像质量控制工作者的责任心和重视度，希望随着质量控制工作的稳步推进，能够真正提升山东省内影像医疗水平，为百姓提供有温度有质量的优质医疗服务。
        </div>
        <div>
          <img src="@/assets/sdsyx/5.png" alt="">
        </div>

      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "SDSHYJB",

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>